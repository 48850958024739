import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import HeroSection from "../components/Home/Hero";
import VideoSection from "../components/Home/VideoSection";
import FaqSection from "../components/Home/FaqSection";
import Cta from "../components/Cta";
import ImageSlider from "../components/About/ImageSlider";
import CardImage from "../components/About/CardImage";
import Heroes from "../components/About/Heroes";
import CardsWithIconSection from "../components/Contact/CardWithIconSection";
import ContactForm from "../components/Contact/ContactForm";
import Careers from "../components/Careers";
import { FeatureWithImage } from "../components/Home/FeaturesWithImage/FeatureWithImage";
import { FeaturesCTA } from "../components/Solution/FeauresCTA";
import Gallery from "../components/Solution/Gallery";
import SolutionSection from "../components/Solution/SolutionSection";
import { SolutionFeatures } from "../components/Solution/Features";
import SEO from "../components/SEO";

export const pageQu = graphql`
	query Solution($id: String!) {
		sanitySolution(id: { eq: $id }) {
			title
			slug {
				current
			}
			i18n_lang
			_rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

const Solution = ({ data, location }) => {
	const page = data.sanitySolution;
	const lang = page.i18n_lang;
	// []
	const content = (page._rawContent || []).map((c, i) => {
		let el = null;
		switch (c._type) {
			case "heroSection":
				el = <HeroSection key={c._key} {...c} />;
				break;
			case "heroSolution":
				el = <SolutionSection key={c._key} {...c} />;
				break;
			case "gallery":
				el = <Gallery key={c._key} {...c} />;
				break;
			case "featuresCTA":
				el = <FeaturesCTA key={c._key} {...c} />;
				break;
			case "solutionFeatures":
				el = <SolutionFeatures key={c._key} {...c} />;
				break;
			case "featureWithImage":
				el = (
					<FeatureWithImage
						className="w-10/12 mx-auto py-32"
						key={c._key}
						{...c}
					/>
				);
				break;
			case "videoSection":
				el = <VideoSection key={c._key} {...c} />;
				break;
			case "faqSection":
				el = <FaqSection key={c._key} {...c} />;
				break;
			case "cta":
				el = <Cta key={c._key} {...c} />;
				break;
			case "cardImage":
				el = <CardImage key={c._key} {...c} />;
				break;
			case "imageSlider":
				el = <ImageSlider key={c._key} {...c} />;
				break;
			case "heroes":
				el = <Heroes key={c._key} {...c} />;
				break;
			case "cardsIcon":
				el = <CardsWithIconSection key={c._key} {...c} />;
				break;
			case "contactForm":
				el = <ContactForm lang={lang} key={c._key} {...c} />;
				break;
			case "careersTable":
				el = <Careers key={c._key} {...c} />;
				break;

			default:
				el = null;
		}

		return el;
	});

	return (
		<>
			<SEO title={page?.title} description={page?.description} />
			<Layout
				location={location}
				dir={lang === "ar" ? "rtl" : "lrt"}
				className={`${lang === "en" ? "font-Inter" : "font-Cairo"}`}
				lang={lang}
			>
				{content}
			</Layout>
		</>
	);
};

export default Solution;
