import React from "react";
import classNames from "classnames";
import { FigureImage } from "../../Atomics/FigureImage";
import { motion } from "framer-motion";
import { Button } from "../../Atomics/Button";
export const FeaturesCTA = ({
	features,
	image,
	header,
	button,
	subheader,
	imageLeft,
	className,
}) => {
	return (
		<section className="py-10">
			<div
				className={classNames(
					"w-10/12 mx-auto ",
					" grid lg:grid-cols-2 gap-12 grid-rows-1",
					className
				)}
			>
				{features && (
					<div
						className={classNames(
							"flex flex-col justify-center row-start-2 lg:row-auto",
							imageLeft
								? "lg:col-start-2 lg:col-end-3"
								: " lg:col-start-1 lg:col-end-2"
						)}
					>
						<>
							{header && (
								<motion.h2
									initial="hidden"
									whileInView="visible"
									viewport={{ once: true }}
									transition={{ duration: 1 }}
									variants={{
										visible: { y: 0, opacity: 1 },
										hidden: { opacity: 0 },
									}}
									className={classNames(
										"text-3xl md:text-4xl xl:text-5xl rtl:lg:text-3xl text-[#323B4B] font-bold mb-9 lg:rtl:leading-normal",
										className
									)}
								>
									{header}
								</motion.h2>
							)}
							{subheader && (
								<motion.p
									initial="hidden"
									whileInView="visible"
									viewport={{ once: true }}
									transition={{ duration: 1 }}
									variants={{
										visible: { y: 0, opacity: 1 },
										hidden: { opacity: 0 },
									}}
									className={classNames(
										"font-medium xl:text-xl text-[#8A94A6] mb-8",
										className
									)}
								>
									{subheader}
								</motion.p>
							)}
						</>
						<ul className="grid gap-4">
							{features &&
								features.map((item, index) => {
									return (
										<li
											key={index++}
											className=" first:mt-0 flex items-center gap-4 lg:text-lg"
										>
											<span>{CheckMark}</span>
											<span>{item}</span>
										</li>
									);
								})}
						</ul>
						<div className="mt-8">
							{button && (
								<Button
									buttonWidth={button.buttonWidth}
									buttonType={button.buttonType}
									route={button.route}
									externalURL={button.link}
									text={button.text}
								/>
							)}
						</div>
					</div>
				)}
				<div
					className={classNames(
						imageLeft && "col-start-1 col-end-2 row-start-1"
					)}
				>
					<FigureImage
						figureClassName={classNames(
							"bg-[#FAFBFC] rounded-2xl  aspect-square "
						)}
						className="w-full rounded-2xl bg-white aspect-square object-cover "
						node={image}
					/>
				</div>
			</div>
		</section>
	);
};

const CheckMark = (
	<svg
		width="36"
		height="37"
		viewBox="0 0 36 37"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="18" cy="18.5" r="18" fill="#EAFAF3" />
		<path
			d="M27 13L14.625 25L9 19.545"
			stroke="#2DCA8C"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
