import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
export const SolutionFeatures = ({
	features,
	header,
	description,
	subheader,
	className,
}) => {
	return (
		<section className="py-10 bg-[#FAFBFC]">
			<div
				className={classNames(
					"w-11/12 xl:w-10/12 mx-auto rounded-3xl bg-white p-8",
					className
				)}
			>
				<div className={classNames("flex flex-col justify-center")}>
					{header && (
						<motion.h2
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { opacity: 0 },
							}}
							className={classNames(
								"text-2xl md:text-4xl xl:text-5xl text-[#323B4B] font-bold mb-9 rtl:leading-normal",
								className
							)}
						>
							{header}
						</motion.h2>
					)}
					{description && (
						<motion.p
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { opacity: 0 },
							}}
							className={classNames(
								" xl:text-xl text-[#8A94A6] mb-8",
								className
							)}
						>
							{description}
						</motion.p>
					)}
					{subheader && (
						<motion.p
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { opacity: 0 },
							}}
							className={classNames(
								"font-medium text-xl xl:text-2xl mb-8",
								className
							)}
						>
							{subheader}
						</motion.p>
					)}
					{features && (
						<ul className="grid gap-4">
							{features &&
								features.map((item, index) => {
									return (
										<li
											key={index++}
											className=" first:mt-0 flex items-center gap-4  lg:text-lg"
										>
											<span>{CheckMark}</span>
											<span className="">{item}</span>
										</li>
									);
								})}
						</ul>
					)}
				</div>
			</div>
		</section>
	);
};

const CheckMark = (
	<svg
		width="36"
		height="37"
		viewBox="0 0 36 37"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="18" cy="18.5" r="18" fill="#EAFAF3" />
		<path
			d="M27 13L14.625 25L9 19.545"
			stroke="#2DCA8C"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
